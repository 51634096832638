import { Button } from '@mui/material'
import React from 'react'
import { MdArrowForward } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

function DiscutionButton({vr  , col }) {
  const navigate = useNavigate()
  return (
    <Button style={{zIndex : '1' }} onClick={()=>{navigate('/contact')}}    size='large' variant={vr ? vr : 'contained'}  color={ col? col :   'primary'} endIcon={<MdArrowForward/>}  >Discutons votre projet</Button>

  )
}

export default DiscutionButton