import React, { useRef } from 'react'
import { ColoredText, MyHero, SR } from '../StyledComponents'
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material'
import { MdArrowForward } from 'react-icons/md'
import DiscutionButton from './DiscutionButton'
import { grey } from '@mui/material/colors'
import MyIcon from './Icon'
import { IoLogoFacebook } from 'react-icons/io'
import { GrGithub, GrInstagram, GrLinkedin } from 'react-icons/gr'
import RealisationsBtn from './RealisationsBtn'
import {motion, useInView} from 'framer-motion'

function Hero() {
    const theme = useTheme() 
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const ls = useRef()
    const rs = useRef()
    const inView = useInView( ls ,  {once :false}   )
  return (
    
    <Box bgcolor={grey[100]}  >
    <MyHero  >
        {/* ///   left section   // */}

        <Box 
        ref={ls}
        width={ isMobile ? '100%'  : '50%'} 
        textAlign={''}

        style={{
          transform: inView ? "none" : "translateX(-100px)",
          opacity: inView ? 1 : 0,
          transition: "all 0.25s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }}
        >
              <Typography variant='h2' color={theme.palette.primary.main}><b> <ColoredText>Boostez</ColoredText> votre présence en ligne</b></Typography>
              <Typography my={4} variant='subtitle1' color={grey[700]}>Modernisez votre présence digitale avec des sites web innovants et dynamiques. Des solutions web adaptées à vos besoins pour une présence en ligne optimale et engageante.</Typography>
             <SR alignItems={isMobile ? "str" : "center"} gap={1} flexDirection={isMobile && "column"} >
              <DiscutionButton  />
              <RealisationsBtn />
            </SR>
            <SR  mt={5} gap={5} alignSelf={'center'}  justifyContent={isMobile && "center"} sx={{  transformOrigin : isMobile ?'center' : 'left' ,scale : "0.8"   }}>
                <MyIcon float={true}> <IoLogoFacebook />  </MyIcon>
                <MyIcon> <GrInstagram />  </MyIcon>
                <MyIcon> <GrGithub />  </MyIcon>
                <MyIcon> <GrLinkedin />  </MyIcon>
            </SR>
        </Box >
        { 
        <Box 
        position={'relative'}
        style={{
          transform: inView ? "none" : "translateX(100px)",
          opacity: inView ? 1 : 0,
          transition: "all 0.25s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          
        }}
          ref={rs}
         width='70%' display={'flex'} justifyContent={isMobile ? 'center' : 'end'} >
            <img width ={isMobile ? '100%':'80%'}  src={require("../images/who.png")} alt="" srcset="" />
            {/* <img   width = src="https://img.freepik.com/free-photo/3d-rendering-cartoon-boy_23-2150797600.jpg?t=st=1714769366~exp=1714772966~hmac=6328742df2d6a32d15ae6d0ae1d48022eeaa75ee7d21440bbaaa59c306ae324c&w=740" alt="" srcset="" /> */}
            {/* <Box position={'absolute'} bottom={0}>
              <img width ={isMobile ? '100%':'50%'}  src={require("../images/growth.jpg")} alt="" srcset="" />

            </Box> */}
        </Box>
        }
        
    </MyHero>
    </Box>
  )
}

export default Hero