import React from 'react'
import { ColoredText, SR } from '../StyledComponents'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import {motion} from "framer-motion"
import { useNavigate } from 'react-router-dom'

function Logo({footer}) {
    const theme = useTheme()
    const isMobile  = useMediaQuery(theme.breakpoints.down('sm'))
    const navigate = useNavigate()
  return (
    <motion.div
    initial={{
      x : -50 ,
      
    }}
    animate={{
      x : 0
    }}
    style={{cursor : "pointer"}}
    onClick={()=>{ navigate('/')}}
    
    >
      <SR gap={1} >
        {/* <Box bgcolor={theme.palette.primary.main} sx={{width : isMobile ?'40px' : '50px' , aspectRatio :'1/1' , display :'flex' , justifyContent :' center' , alignItems : 'center' , borderRadius :'50%' , color : 'white'}}>
            <></>

        </Box> */}
        <img src={require(`../images/lg.png`)} alt="" srcset="" width={isMobile ?"30px" :  '50px'} />
        <Typography letterSpacing={1} color={ footer ? 'white' :  theme.palette.primary.main} variant={isMobile ? "h5" : 'h4'} ><b>Code<ColoredText  >Lab</ColoredText></b></Typography>
    </SR>
    </motion.div>
  )
}

export default Logo