import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { grey } from '@mui/material/colors'
import React from 'react'
import { Line, SR } from '../StyledComponents'
import { MdArrowForward } from 'react-icons/md'
import WebCategorie from './WebCategorie'

export default function Categories({sp}) {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const cats = [{
        src : 'https://img.freepik.com/free-psd/e-commerce-furniture-landing-page_197582-341.jpg?w=1060&t=st=1717719140~exp=1717719740~hmac=48b46bb390b20c997057bd494a67339fb2b641fc9e63c6853d11a93cd09a6a14' , 
        title : 'Vitrine', 
        desc : "Présentez vos produits, vos services d'une maniére moderne et touchez de nouveaux clients !"
    } , {
        src : 'https://img.freepik.com/free-psd/e-commerce-furniture-landing-page_197582-336.jpg?t=st=1717719125~exp=1717722725~hmac=eeb30dbb653da728dd759eb7b774b51e4bc58733d1fefe08f263ecf59bdbf61d&w=1060' , 
        title : 'E-commerce', 
        desc : 'Vendez facilement vos produits ou services en ligne depuis votre site web'
    } , {
        src : 'https://cdn.dribbble.com/userupload/10946999/file/original-4da08d159e969ca90a6411d1fc75ed82.png?resize=1200x900' , 
        title : 'Présentation', 
        desc : 'Outil essentiel dans le marketing digital, optimisé pour générer des conversions'
    } , {
        src : 'https://cdn.dribbble.com/userupload/5011472/file/original-2426331e7de82bfe0dabff6b77baae2d.png?resize=752x' , 
        title : 'Complex Web-applications', 
        desc : 'Transformez vos idées en réalité digitale avec notre expertise créative unique.'
    } , 
]
  return (
    <Box 
    sx={{background : !sp &&`url(${require('../images/b.png')})` , backgroundSize :'cover' }}
    minHeight={'100vh'}  justifyContent={'center'} display={'flex'} alignItems={'center'}  >
    <Box width={'85vw'} margin={'2cm auto'}  >
        <Box  textAlign={'center'} >
            <Typography  variant='h4' color={theme.palette.primary.main}>Le web sans complications : des sites web intuitifs et faciles à utiliser.</Typography>
            <Typography color={grey[500]} mt={1} variant='body2'>ASSUREZ VOTRE PRÉSENCE EN LIGNE DE FAÇON PROFESSIONNELLE ET SÉCURISANTE POUR VOS CLIENTS</Typography>
        </Box>
        <SR justifyContent={'center'}my={4} ><Line  /></SR>

         <Grid container gap={1} justifyContent={'space-evenly'} bgcolor={''}   >

            {cats.map((cat , i)=><WebCategorie key={cat.title} cat={cat}  delay={(i+1)*0.31} />)}
                
               

        </Grid>
        {/* <SR width={'100%'} mt={10}  justifyContent={'center'} bgcolor={''} >        <Button  size='large' variant='outlined'  color='secondary' endIcon={<MdArrowForward/>}  >Voir plus</Button></SR> */}

        { !isMobile &&  
        <Box mt={'2cm'} p={6} textAlign={'center'} color={sp ? theme.palette.primary.main : 'white'}  >
            <Typography variant='h4'>Chez OBROV, votre réussite est notre priorité ! </Typography>
            <Typography  variant='subtitle1' >Nous vous accompagnerons dans votre transformation digitale afin de vous aider à faire décoller votre business.</Typography>
        </Box>}

    </Box>
    </Box>
  )
}
