import { Button } from '@mui/material'
import React from 'react'
import { MdArrowForward } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

function RealisationsBtn(props) {
    const navigate = useNavigate()
  return (
    <Button  {...props} size='large' onClick={()=>{navigate('/examples')}}   endIcon={<MdArrowForward   />} variant='text'> Nos Réalisations</Button>

  )
}

export default RealisationsBtn