import React, { useRef } from 'react'
import { Line, ProcessSec, SC, SR } from '../StyledComponents'
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import PCard from './PCard'
import { grey } from '@mui/material/colors'
import { GrPlan } from 'react-icons/gr'
import { SiAntdesign } from "react-icons/si";
import { MdArrowForward, MdOutlineDeveloperMode } from "react-icons/md";
import { GrDeploy } from "react-icons/gr";
import { useInView } from 'framer-motion'




function Process() {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const ref      =  useRef()
    const inView   = useInView(ref , {once  : false})
    

  return (
    <Box sx={{background : `url(${require('../images/bb.png')})` , backgroundSize :'cover'  , minHeight :"110vh" , marginTop :'-2px' }} pb={3} display={'flex'} alignItems={'center'} >
    <ProcessSec  container >
        <Grid  item md={5.5}  xs={11} color={"white"}>
            <SC
            ref={ref}
            style={{
                transform: inView ? "none" : "translateX(-100px)",
                opacity: inView ? 1 : 0,
                transition: "all 0.25s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
              }}
            gap={4}  alignItems={isMobile &&'center'}    >
                <Typography variant='h3'>Processus de travail</Typography>
                <Line bg={'white'} />
                <Typography color={grey[300]}   variant='subtitle1'>La création d'une application Web implique plusieurs étapes, de la conceptualisation au déploiement. Tout au long du processus pour garantir que le produit final</Typography>

            </SC>
        </Grid>
        <Grid item  md={6} xs={12} bgcolor={''} display={'flex'} justifyContent={isMobile ?'center' : 'end'} >
            <Grid container md={12} justifyContent={isMobile ?'center' : 'end'} bgcolor={''}   xs={9}  gap={2}>
                 
                <PCard  delay={0.3*1}    title={'Idée et Planification'} desc={'Définir le but et les objectifs de votre application web et Planifier les fonctionnalités  de votre application '}       >
                    <GrPlan fontSize={'30px'} /> 
                </PCard>
                <PCard  delay={0.3*2}  title={'Conception et Design'} desc={"Créer des wireframes ou des maquettes pour visualiser la mise en page et l'interface utilisateur."}  >  
                    <SiAntdesign  fontSize={'30px'} /> 
                </PCard>
                <PCard  delay={0.3*3}  title={'Développement '} desc={"Choisir les technologies appropriées pour votre application web (frontend, backend, et base de données)"}  >  
                    <MdOutlineDeveloperMode fontSize={'30px'} /> 
                </PCard>
                <PCard  delay={0.3*4}  title={'Déploiement '} desc={'Configurer votre environnement serveur et le configurer pour exécuter votre application et Déployer le code sur le serveur'}  >  
                    <GrDeploy fontSize={'30px'} /> 
                </PCard>
               
            </Grid>
        </Grid>

        {/* <SR width={'100%'} mt={10}  justifyContent={'center'} bgcolor={''} >        <Button  size='large' variant='outlined'  color='secondary' endIcon={<MdArrowForward/>}  >Plus de détails</Button></SR> */}

    </ProcessSec>
    </Box>
  )
}

export default Process