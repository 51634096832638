import logo from './logo.svg';
import './App.css';
import { Box, Button, Typography } from '@mui/material';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import About from './components/About';
import Process from './components/Process';
import Projects from './components/Projects';
import WhatIDo from './components/WhatIDo';
import DoYouHaveIdea from './components/DoYouHaveIdea';
import DiscussProjet from './components/DiscussProjet';
import Footer from './components/Footer';
import { useEffect, useRef } from 'react';
import PreFooter from './components/PreFooter';
import Categories from './components/Categories';
import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import Accueil from './pages/Accueil';
import Aprops from './pages/Aprops';
import ContactPage from './pages/ContactPage';
import ServicesPage from './pages/ServicesPage';
import RealisationsPage from './pages/RealisationsPage';
import { Services } from './StyledComponents';
import ProcessPage from './pages/Process';



function App() {
  const nav = useRef()
  const location = useLocation()
  const AboutRef = useRef()
  const PortfolioRef = useRef()
  const ServicesRef = useRef()
  const FormRef = useRef()

 useEffect(()=>{
      nav.current.scrollIntoView()
 },[location.pathname])



  return (
    <Box sx={{overflowX : 'hidden'}}>
       <Box ref={nav} >
        <Navbar  />
       </Box>   
      <Routes>
        <Route path='/' element={<Accueil />}    />
        <Route path='/about' element={<Aprops />}    />
        <Route path='/contact' element={<ContactPage />}    />
        <Route path='/services' element={<ServicesPage />}    />
        <Route path='/examples' element={<RealisationsPage />}    />
        <Route path='/process' element={<ProcessPage />}    />

      </Routes>
      <Footer />

      </Box>
      
  
  );
}

export default App;



