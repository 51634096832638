import React, { useState } from 'react'
import { MLink, MyList, Nvbar, SR } from '../StyledComponents'
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material'
import Logo from './Logo'
import { RxHamburgerMenu } from "react-icons/rx";
import { IoMdClose } from "react-icons/io";
import { grey } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import RealisationsBtn from './RealisationsBtn';
import {motion} from "framer-motion"



function Navbar({}) {
  
    const theme  = useTheme()
    const isMobile  = useMediaQuery(theme.breakpoints.down('sm'))
    const nav = useNavigate()
    const [scale , setScale] = useState(false)
    const goToContactPage=()=>{
      nav('/contact')
    }
  return (
    <motion.div 
   
    >
      <Nvbar>
          <Logo />
          <MyList gap={4} s={scale} >
              <MLink  to={'/'}    variant='subtitle1'   onClick={()=>{setScale(false) }}     >Accueil </MLink>
              <MLink to={'/examples'} variant='subtitle1'  onClick={()=>{setScale(false)}}   >Nos Réalisations</MLink>
              <MLink to={'/services'} variant='subtitle1'  onClick={()=>{setScale(false)}}   >Nos Services</MLink>
              <MLink    to={'/process'}     variant='subtitle1'  onClick={()=>{setScale(false) }}   >  Méthodologie</MLink>
              <MLink    to={'/about'}     variant='subtitle1'  onClick={()=>{setScale(false) }}   >    À Propos</MLink>
              {isMobile && 
              <SR >

                <Button  onClick={goToContactPage} color='info'  variant='contained'>Contact</Button>
                {/* <RealisationsBtn color='info' /> */}
              </SR>
              }
          </MyList>
          { isMobile ? 
          <Box   onClick={()=>{setScale(!scale)}}style={{zIndex : '9' , fontSize : '24px' , color :scale && "white" }}  >
              {scale ? <IoMdClose  /> :     <RxHamburgerMenu />}
          </Box>
            :  <SR >

                <Button    variant='contained'onClick={()=>{setScale(false) ; goToContactPage() }} >Contact</Button>
                {/* <RealisationsBtn /> */}
              </SR>
            
            }
      </Nvbar>
    </motion.div>
  )
}

export default Navbar