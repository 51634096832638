import React from 'react'
import About from '../components/About'
import PreFooter from '../components/PreFooter'
import DoYouHaveIdea from '../components/DoYouHaveIdea'

function Aprops() {
  return (
    <>
    <About sp={true} />
    {/* <PreFooter /> */}
    <DoYouHaveIdea />
    </>
  )
}

export default Aprops