import React from 'react'
import { SC, ServicePage } from '../StyledComponents'
import { Box, Button, Typography, useTheme } from '@mui/material'
import Servicea from '../components/Servicea'
import { grey } from '@mui/material/colors'
import Categories from '../components/Categories'
import PreFooter from '../components/PreFooter'
import DiscutionButton from '../components/DiscutionButton'
import DoYouHaveIdea from '../components/DoYouHaveIdea'

function ServicesPage() {
    const theme = useTheme()
  return (
    <Box bgcolor={grey[100]} alignItems={'center'} >
          <Box  width={'100%'} textAlign={'center'} color={'white'} py={2} bgcolor={theme.palette.secondary.main}>
                        <Typography variant='h6'>Nos offres contient plusieurs types des sites</Typography>
          </Box>
   
          
            <Categories sp={true} />
            <DoYouHaveIdea  />
     </Box>
  )
}

export default ServicesPage