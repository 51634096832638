import React from 'react'
import { Aboutme, ColoredText, Line, MyHero, SC, SR } from '../StyledComponents'
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material'
import { RxArrowDown, RxArrowRight } from 'react-icons/rx'
import { IoIosCodeWorking, IoMdApps } from 'react-icons/io'
import { grey } from '@mui/material/colors'
import { GrMail } from 'react-icons/gr'
import { useNavigate } from 'react-router-dom'
import DiscutionButton from './DiscutionButton'
import { MdArrowForward } from 'react-icons/md'
import RealisationsBtn from './RealisationsBtn'
import Logo from './Logo'

export default function About({sp}) {
    const theme = useTheme() 
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const navigate = useNavigate()

    const GoTo = (url)=>{
        navigate(url)
    }

  return (
    <Box  display={'flex'} alignItems={'center'} sx={{background :(!sp &&  !isMobile )&& `url(${require('../images/a.png')})` , backgroundSize :'cover'  }} bgcolor={sp && grey[50]} >
            <Aboutme gap={3}   >
                {!isMobile && 
                <Box width={  '50%'} bgcolor={theme.palette.primary.main}   display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    
                    <Box  bgcolor={"rgb(255,250,254 , 1)"} sx={{aspectRatio  : '1/1' , borderRadius :"50%"}} display={'flex'} justifyContent={'center'} alignItems={'center'}  >
                     <Typography mx={4} variant='h1' color={'white'}>
                        <Logo />
                     </Typography>
                    </Box>

                </Box>}

                <SC width={ isMobile ? '100%' : '50%'}gap={ 3} bgcolor={''} justifyItems={'center'} py={"1cm"} pr={isMobile ? 0 : 2} >
                    <Typography variant={isMobile ? 'h5' :   'h4'} color={theme.palette.primary.main}><b>Qui sommes-nous ?</b> </Typography>
                    <Line />
                    <Typography variant='h6'>CODELAB est une agence de communication digitale spécialisée à la création des sites web </Typography>
                    <Typography variant='subtitle1' color={grey[600]}>Nous nous spécialisons dans la création de sites web personnalisés répondant aux besoins uniques de chaque client. Quelle que soit votre idée, des pages d'atterrissage simples aux plateformes de commerce électronique complexes, nous offrons une gamme de services pour aider les entreprises à établir une forte présence en ligne. Nous fournissons une maintenance et un support continus pour garantir que votre site web reste sécurisé et à jour.</Typography>
                    <Typography variant='subtitle1' color={grey[600]}>Nos offres nous permettent de répondre de façon complète à l’enjeu que représente internet aujourd’hui : Un site internet de <ColoredText>qualité</ColoredText>, <ColoredText>SEO Friendly</ColoredText>, rapide et entièrement  <ColoredText>sécurisé </ColoredText>.</Typography>

                    <SR gap={2} justifyContent={ isMobile ?    'center'  : 'start'  } flexDirection={isMobile ? "column" : "row"}>
                        {/* <Button onClick={()=>{GoTo('/contact')}}  endIcon={<GrMail  size={"14px"} />}  variant='contained'>Discutons votre projet </Button> */}
                        <DiscutionButton />
                        <RealisationsBtn />
                    </SR>
                </SC>
            </Aboutme>
    </Box>
  )
}
