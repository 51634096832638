import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import Project from './Project'
import { Line, SR } from '../StyledComponents'
import { MdArrowForward } from 'react-icons/md'
import { grey } from '@mui/material/colors'
import { useTheme } from '@emotion/react'
import { useNavigate } from 'react-router-dom'

function Projects({sp}) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const projects = [ 
  {
    title : "Boutique" ,
    desc : 'Un outil de suivi régulier des services publics en ligne, en se basant sur VOTRE EXPÉRIENCE usager' , 
    img : 'stor.jpeg'
  },{
    title : " Dentiste " ,
    desc : 'Un outil de suivi pour gérervotre boutique en ligne, les  produits, les commandes et les messages des clients' , 
    img : 'dentist.jpeg'
  },{
    title : "Restaurant " ,
    desc : 'PPharmaGuard fournit des soins de santé progressifs et abordables, accessibles à tous sur mobile et en ligne' , 
    img : 'resto.jpeg'
  }] 

  const moreExamples = [{
    title : "GYM" ,
    desc : 'Un outil de suivi régulier des services publics en ligne, en se basant sur VOTRE EXPÉRIENCE usager' , 
    img : 'gym.jpeg'
  },{
    title : "Opticien" ,
    desc : 'Un outil de suivi pour gérervotre boutique en ligne, les  produits, les commandes et les messages des clients' , 
    img : 'opt.jpeg'
  },{
    title : "Assurance " ,
    desc : 'PPharmaGuard fournit des soins de santé progressifs et abordables, accessibles à tous sur mobile et en ligne' , 
    img : 'assur.jpeg'
  },{
    title : "Présentaion d'un produit " ,
    desc : 'PPharmaGuard fournit des soins de santé progressifs et abordables, accessibles à tous sur mobile et en ligne' , 
    img : 'vr.png'
  },{
    title : "école " ,
    desc : 'PPharmaGuard fournit des soins de santé progressifs et abordables, accessibles à tous sur mobile et en ligne' , 
    img : 'school.jpeg'
  },{
    title : "Healthcare " ,
    desc : 'PPharmaGuard fournit des soins de santé progressifs et abordables, accessibles à tous sur mobile et en ligne' , 
    img : 'pharma.png'
  }]
  const navigate = useNavigate()
  return (
    <Box
    marginTop={isMobile &&'1cm'}
    pt={'1cm'}
    minHeight={'110vh'} sx={{background :(!sp && !isMobile )&& `url(${require('../images/pro.png')})` , backgroundSize :'cover' }} justifyContent={'center'} display={'flex'} alignItems={'center'} bgcolor={sp && grey[100]} >
    <Box width={'85vw'} margin={' auto'}  >
        <Box  textAlign={'center'} >
            <Typography   
             color={theme.palette.primary.main}
              
               variant='h4'>Envie de voir quelques références ?</Typography>
            {/* <Typography mb={5} mt={1} variant='subtitle2'>Here are some of my projects. I prefered quality over quantity to show my skills</Typography> */}
        </Box>
        <SR justifyContent={'center'} ><Line mt={4} /></SR>

         <Grid container  justifyContent={'center'}my={9.5} gap={2} bgcolor={''}   >
          {projects.map((pr , i)=>{
            return <Project key={pr.title} delay={(i+1) * 0.3}   pr={pr} />
          })}
          {sp &&  moreExamples.map((pr , i)=>{
            return <Project key={pr.title} pr={pr} delay={(i+2) * 0.3} />
          })}
           
                

        </Grid>
      {!sp &&  <SR width={'100%'}  justifyContent={'center'} bgcolor={''} mb={5} >        
        <Button onClick={()=>{navigate('/examples')}} size='large' variant='contained'  color='primary'  endIcon={<MdArrowForward/>}  >Voir plus</Button>
        </SR>}

    </Box>
    </Box>
  )
}

export default Projects