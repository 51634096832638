import { Box, Button, Grid, Typography, useTheme } from '@mui/material'
import { grey } from '@mui/material/colors'
import React, { useRef } from 'react'
import { MdAppShortcut, MdArrowForward, MdArrowRightAlt, MdStar } from 'react-icons/md'
import { ProjectImg, SR } from '../StyledComponents'
import { useInView } from 'framer-motion'

function Project({pr  ,delay}) {
  const theme = useTheme()
  const ref  =  useRef()
  const inView = useInView(ref , {once  : true})
  return (
    <Grid 
    ref={ref}
    style={{
      transform: inView ? "none" : "scale(0.8)",
      opacity: inView ? 1 : 0,
      transition: `all ${delay}s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s`
    }}
    item md={3}  xs={11} 
    bgcolor={"white"}
    sx={{display :'flex' , flexDirection : 'column'  , alignItems :'center' , position :'relative'  , backgroundSize :'cover'   }}   
    boxShadow={'-3px 5px 50px -28px rgba(0,0,0,0.75)' } 
    borderRadius={3}
     
     >
                  
                {/* <img src="https://cdn.dribbble.com/users/1145170/screenshots/15988580/media/34c5f14498102f88b82cf330229b773a.png" alt="" srcset="" /> */}
                <ProjectImg url={require(`../images/${pr.img}`)} 
                sx={{  borderBottomLeftRadius : 3 , borderBottomRightRadius : 3}}
                borderRadius={3}
                />
                
                {/* <Typography variant='subtitle1'  px={2} >{pr.title}</Typography>
                <Typography variant='body2' px={2} color={grey[500]} >{pr.desc}</Typography> */}
                 <SR 
                // sx={{position : 'absolute' , bottom : -60  }}
                
                p={2}
                width={'100%'}  justifyContent={'space-between'} >  
                <SR>
                  <MdStar />     
                 <Typography variant='body1' pl={1}  >{pr.title}</Typography>
                </SR>
                
                {/* <Button  sx={{borderRadius : 8  , padding : "5px"}}  endIcon={<MdArrowRightAlt />}  variant='text' size='small' color='primary' >View</Button> */}

                </SR>

    </Grid>
  )
}

export default Project