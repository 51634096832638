import React from 'react'
import Projects from '../components/Projects'
import DoYouHaveIdea from '../components/DoYouHaveIdea'

function RealisationsPage() {
  return (
    <>
    <Projects sp={true} />
    <DoYouHaveIdea />
    </>
  )
}

export default RealisationsPage