import React from 'react'
import Hero from '../components/Hero'
import Process from '../components/Process'
import Projects from '../components/Projects'
import WhatIDo from '../components/WhatIDo'
import Categories from '../components/Categories'
import DoYouHaveIdea from '../components/DoYouHaveIdea'
import About from '../components/About'
import ContactPage from './ContactPage'

function Accueil() {
  return (
    <>
      <Hero />
    <About  sp={false}/>
    <Categories />
    <Process />
    <Projects />
    <ContactPage />
    <DoYouHaveIdea />
    </>
  )
}

export default Accueil