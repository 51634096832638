import React from 'react'
import { Icon } from '../StyledComponents'
import { grey, purple } from '@mui/material/colors'
import { useTheme } from '@mui/material'

function MyIcon({children , float }) {
  const theme  = useTheme()
  return (
    <Icon bgcolor={ float ? theme.palette.secondary.main  : "white"} color={!float ? theme.palette.secondary.main  : grey[100]} boxShadow={"2px 4px 76px -10px rgba(0,0,0,0.75)"}>{children}</Icon>
  )
}

export default MyIcon