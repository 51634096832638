import React from 'react'
import Process from '../components/Process'
import DoYouHaveIdea from '../components/DoYouHaveIdea'

function ProcessPage() {
  return (
    <>
    <Process />
    <DoYouHaveIdea />
    </>
  )
}

export default ProcessPage